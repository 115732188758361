import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';

type routerType = {
  redirect?: string | string[];
};

type InitialState = {
  routerStore: routerType | null;
};

const initialData: InitialState = {
  routerStore: null,
};

let store = createStore<InitialState>(() => initialData);

export const setRouterStore = (routerStore: {
  redirect: string | string[] | undefined;
}) => {
  store.setState({ routerStore });
};

export function hydrate(initialData: InitialState) {
  store = createStore<InitialState>(() => initialData);
}

export function reset(
    newValue?: (current: InitialState) => Partial<InitialState>
) {
  const current = store.getState();
  store.setState({ ...initialData, ...(newValue?.(current) ?? {}) });
}

export function state() {
  return store.getState();
}

export const useRouterStore = () => useStore(store, (s) => s);
