import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Badge, Popover, IconButton } from '@mui/material';
import { useCurrentCart } from '@bamboo/core-lib/src/hooks/cart';
import {
  calculateItemPrice,
  removeCartItem,
} from '@bamboo/core-lib/src/api/flows/cart';
import HiddenComponent from '@bamboo/core-lib/src/components/HiddenComponent';
import { fmtCurrency } from '@bamboo/ts-utils';
import Link from 'next/link';
import CartItems from './CartItems';
import { notify } from '@bamboo/core-lib/repo/notification';
import Cart from '@bamboo/core-lib/components/Icons/Cart';
import Icons from '@bamboo/ui-lib/src/icons';
import { useMediaQuery } from '@mui/material';
import { removeFromCart } from '@/lib/seo/GAEvents';

const ActionButton = styled(IconButton)(() => ({
  boxSizing: 'border-box',
  transition: 'all 0.3s ease 0s',
  padding: '0px',
  fill: 'rgb(150, 150, 150)',
  color: 'rgb(150, 150, 150)',
  backgroundColor: 'rgba(255, 153, 102, 0)',
  height: '24px',
  marginTop: '-4px',
  marginBottom: '-1px',
}));

const CloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: '0px',
  right: '10px',
  padding: '0px',
  color: '#818a91',
}));

const CloseIcon = styled(Icons.CloseIcon)(() => ({}));

const PopoverContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
  backgroundColor: 'rgb(255, 255, 255)',
  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 20px',
  transform: 'translateX(0px)',
  borderRadius: '22px',
  height: 'fit-content',
  width: '330px',
  transition: 'all 0s ease 0s',
  maxHeight: '85vh',
  padding: '34px 30px',
}));

const PopoverHeader = styled('div')(() => ({
  boxSizing: 'border-box',
  position: 'relative',
  paddingBottom: '10px',
  display: 'flex',
  flexDirection: 'column',
}));
const PopoverFooter = styled('div')(() => ({
  boxSizing: 'border-box',
  position: 'relative',
  paddingTop: '10px',
  display: 'flex',
  flexDirection: 'column',
}));

const ListContainer = styled('div')(() => ({
  overflow: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: '#dad7d7 #F4F4F4',
  marginRight: '-8px',
  paddingRight: '0px',
  paddingBlock: '10px',
  borderBlock: '1px rgb(235, 235, 228) solid',
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#F4F4F4',
  },
  '&::-webkit-scrollbar': {
    width: 8,
    background: '#F4F4F4',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#dad7d7',
  },
}));

const Title = styled('span')(() => ({
  boxSizing: 'border-box',
  fontSize: '18px',
  fontWeight: 600,
  marginBottom: '5px',
  color: '#1ababc',
  display: 'flex',
  alignItems: 'center',
}));

const Label = styled('span')(() => ({
  fontSize: '20px',
  lineHeight: '20px',
  fontWeight: 400,
  letterSpacing: '-0.1px',
  color: '#544F4F',
  marginLeft: '10px',
}));

const Price = styled('span')(() => ({
  boxSizing: 'border-box',
  fontSize: '15px',
  fontWeight: 500,
  color: 'rgb(25, 186, 187)',
  marginLeft: '5px',
}));

const ButtonContainer = styled('div')(() => ({
  boxSizing: 'border-box',
  gap: '10px',
  fontSize: '20px',
  textAlign: 'center',
  display: 'grid',
  gridTemplateColumns: '1fr',
  paddingTop: '4px',
}));

const CheckoutButton = styled(Link)(({ theme }) => ({
  boxSizing: 'border-box',
  marginTop: theme.spacing(4),
  transition: 'all 0.3s ease 0s',
  display: 'inline-block',
  lineHeight: 1,
  color: 'rgb(255, 255, 255)',
  fill: 'rgb(255, 255, 255)',
  textAlign: 'center',
  textDecoration: 'none',
  boxShadow: 'none',
  padding: '13px 20px',
  backgroundColor: 'rgb(25, 186, 187)',
  borderRadius: '60px',
  fontSize: '13px',
  fontWeight: 600,
  [theme.breakpoints.down('md')]: {
    margintop: theme.spacing(3.5),
    color: 'rgba(255, 255, 255, 1)',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '19.5px',
  },
}));
const Checkouttext = styled('span')(() => ({
  boxSizing: 'border-box',
  MozBoxFlex: '1',
  flexGrow: 1,
  MozBoxOrdinalGroup: '11',
  order: 10,
  display: 'inline-block',
  textDecoration: 'inherit',
}));

const BadgeStyled = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#f96',
    minWidth: '12px',
    height: '12px',
    minHeight: '12px',
    padding: '4px 4px',
    fontSize: '0.47rem',
  },
}));

const EmptyText = styled('span')(({ theme }) => ({
  boxSizing: 'border-box',
  marginTop: '0px',
  fontSize: '22px',
  lineHeight: 1.1,
  textAlign: 'center',
  color: '#414141',
  marginRight: '10%',
  marginLeft: '10%',
  marginBottom: '5px',
  whiteSpace: 'break-spaces',
  [theme.breakpoints.down('md')]: {
    margin: '0px 27px -9px 27px',
    lineHeight: '23.1px',
    fontWeight: 400,
  },
}));
const CartIconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  color: '#414141',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    marginBottom: '12px',
  },
}));

const DefaultLabel = {
  cartItens: (n: number) => `${n} clipes adicionados`,
  total: 'Total',
  checkout: 'Finalizar compra',
  emptyCart: 'Ops...\nnenhum clipe\na mesa de luz',
  search: 'Comece a pesquisar agora',
  itemRemovedFromCart: 'Item removido da mesa de luz',
  failedToRemoveItemFromCart: 'Falha ao remover Item da mesa de luz',
};

export type CartIconButtonProps = {
  label?: string;
  labels?: {
    cartItens?: (n: number) => string;
    total?: string;
    checkout?: string;
    emptyCart?: string;
    search?: string;
    itemRemovedFromCart?: string;
    failedToRemoveItemFromCart?: string;
  };
};

const CartIconButton = (props: CartIconButtonProps) => {
  const { labels = DefaultLabel, label = '' } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [total, setTotal] = React.useState(0);
  const [content, setContent] = React.useState(0);
  const theme = useTheme();

  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });
  const [cart] = useCurrentCart();
  const cartProducts = cart?.cartProducts ?? [];

  const handleOpen = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onRemoveItem = React.useCallback(
    (caprID: number) => {
      if (!cart) {
        return;
      }
      removeCartItem(cart.cartID, caprID)
        .then(() => {
          notify({
            message: labels?.itemRemovedFromCart ?? '',
            type: 'success',
            temporary: true,
          });
          const prodID = cart.cartProducts.find(
            (c) => (c.caprID = caprID)
          )?.prodID;
          if (prodID) {
            removeFromCart(prodID, cart);
          }
        })
        .catch((e) => {
          notify({
            message: labels?.failedToRemoveItemFromCart ?? '',
            type: 'error',
            temporary: true,
          });
          console.warn(e);
        });
    },
    [cart, labels]
  );

  React.useEffect(() => {
    let total = 0;
    cartProducts.forEach((p) => {
      total += calculateItemPrice(p.price, p.variations);
    });
    setTotal(total);
  }, [cart]);

  React.useEffect(() => {
    setContent(cartProducts.length);
  }, [cartProducts]);

  return (
    <div>
      <BadgeStyled
        showZero
        max={9}
        color="secondary"
        badgeContent={content}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <ActionButton onClick={handleOpen} onMouseOver={handleOpen}>
          <Icons.CartIcon width={28} height={28} />
        </ActionButton>
      </BadgeStyled>
      {label && <Label>{label}</Label>}
      <Popover
        open={!!anchorEl}
        onClose={handleClose}
        anchorEl={anchorEl}
        slotProps={{
          backdrop: { style: { backgroundColor: 'rgba(0,0,0,0.3)' } },
        }}
        PaperProps={{
          sx: (theme) => ({
            borderRadius: '22px',
            [theme.breakpoints.down('md')]: {
              transform: 'translateX(10px)',
            },
          }),
          onMouseLeave: handleClose,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: isSmallerScreen ? 'left' : 'right',
        }}
      >
        <PopoverContainer>
          <HiddenComponent hidden={content === 0}>
            <PopoverHeader>
              <Title style={{ fontSize: '24px' }}>
                <Cart style={{ marginRight: '16px', color: '#1ababc' }} />
                Sua mesa de luz
              </Title>

              <CloseButton onClick={handleClose} edge="end">
                <CloseIcon />
              </CloseButton>
            </PopoverHeader>
            <ListContainer>
              <CartItems small items={cartProducts} onRemove={onRemoveItem} />
            </ListContainer>
            <PopoverFooter>
              <Title style={{ color: 'black', marginInline: 'auto' }}>
                {labels?.cartItens?.(content)}
              </Title>
            </PopoverFooter>
            <ButtonContainer>
              <CheckoutButton href="/checkout">
                <Checkouttext>Finalizar Mesa de Luz</Checkouttext>
              </CheckoutButton>
            </ButtonContainer>
          </HiddenComponent>
          <HiddenComponent hidden={content > 0}>
            <PopoverHeader>
              <CloseButton onClick={handleClose}>
                <CloseIcon />
              </CloseButton>
            </PopoverHeader>
            <div
              style={{
                margin: '25px 0px 60px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <CartIconContainer>
                <Icons.CartIcon width={32} height={32} />
              </CartIconContainer>
              <EmptyText>
                Ops... você ainda não selecionou nenhum vídeo
              </EmptyText>

              <ButtonContainer>
                <CheckoutButton href="/search">
                  <span
                    style={{
                      boxSizing: 'border-box',
                      MozBoxFlex: '1',
                      flexGrow: 1,
                      MozBoxOrdinalGroup: '11',
                      order: 10,
                      display: 'inline-block',
                      textDecoration: 'inherit',
                      fontSize: '16px',
                      fontWeight: 400,
                      marginTop: '0px',
                    }}
                  >
                    {labels?.search}
                  </span>
                </CheckoutButton>
              </ButtonContainer>
            </div>
          </HiddenComponent>
        </PopoverContainer>
      </Popover>
    </div>
  );
};

export default CartIconButton;
