import React from 'react';
import { styled } from '@mui/material/styles';
import { CloseRounded } from '@mui/icons-material';
import Power from '@bamboo/core-lib/src/components/Icons/Power';
import { IconButton, Button } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { navigationConfig } from './navigation.config';
import Link from 'next/link';
import type { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import DrawerDialog from '@bamboo/core-lib/src/components/DrawerDialog';
import LogoutDialog from '@/components/layout/LogoutDialog';
import { useTopBarMenu } from '@/lib/hooks/menu';
import { useSessionStore } from '@bamboo/core-lib/repo/session';
import ProfileIconButton from './ProfileIconButton';
import FavoriteIconButton from './FavoriteIconButton';
import CartIconButton from './CartIconButton';

const ActionButton = styled(IconButton)(() => ({
  marginRight: '1.7%',
  boxSizing: 'border-box',
  borderRadius: '3px',
  transition: 'all 0.3s ease 0s',
  display: 'inline-block',
  lineHeight: 1,
  textAlign: 'center',
  textDecoration: 'none',
  boxShadow: 'none',
  width: 'auto',
  padding: '0px',
  fontSize: '20px',
  fontWeight: 500,
  fill: 'rgb(150, 150, 150)',
  color: 'rgb(150, 150, 150)',
  backgroundColor: 'rgba(255, 153, 102, 0)',
}));

const Root = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: '42px',
  gap: '24px',
}));

const Separator = styled('div')(() => ({
  width: '100%',
  height: '1px',
  background: '#544F4F1F',
}));

const CloseContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  lineHeight: 1,
  color: 'rgb(73, 81, 87)',
  fontSize: '12px',
  marginTop: '0px',
  padding: '15px 15px 0',
  opacity: 1,
}));

const NavContainer = styled('div')(() => ({
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
  display: 'flex',
  MozBoxOrient: 'vertical',
  MozBoxDirection: 'normal',
  flexDirection: 'column',
}));

const ActionsContainer = styled('div')(() => ({
  display: 'flex',
  gap: '5px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
}));

const ActionContainer = styled('div')(() => ({
  display: 'flex',
}));

const List = styled('ul')(() => ({
  display: 'flex',
  gap: '17px',
  flexDirection: 'column',
  listStyle: 'none',
  zIndex: 2,
  margin: '0px 0px 0px 0px',
  padding: '0px',
}));

const ListItem = styled('li')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}));

const NavLink = styled(
  (props: LinkProps & { selected?: boolean; children: React.ReactNode }) => {
    const { selected, ...other } = props;
    return <Link {...other} />;
  }
)(({ theme, selected }) => ({
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  color: '#544F4F',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '20px',
  letterSpacing: '-0.1px',
  ...(selected
    ? {
        borderBottom: '2px solid #544F4F ',
      }
    : {}),
}));

export type SideNavigationProps = {
  color?: string;
  icon: React.ReactNode;
};

const SideNavigation = (props: SideNavigationProps) => {
  const { color, icon } = props;
  const [open, setOpen] = React.useState(false);
  const [logout, setLogout] = React.useState(false);
  const { t } = useTranslation(navigationConfig.i18nNamespaces);
  const router = useRouter();
  const [menu] = useTopBarMenu();
  const { user } = useSessionStore();

  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleLogout = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      if (!user) {
        router.push('/auth/login');
        return;
      }
      setLogout(true);
    },
    [user]
  );

  return (
    <>
      <ActionButton onClick={onOpen} style={color ? { color } : {}}>
        {icon}
      </ActionButton>
      <DrawerDialog open={open} onClose={onClose}>
        <CloseContainer>
          <Button
            onClick={onClose}
            color="inherit"
            sx={{
              fontStyle: 'normal',
              textTransform: 'none',
              fontSize: '12px',
              fontWeight: 300,
              letterSpacing: '1px',
              lineHeight: '12px',
              color: 'rgb(0, 0, 0)',
              '& .MuiButton-endIcon': {
                marginLeft: '0px ',
              },
            }}
            endIcon={<CloseRounded style={{ height: 16, width: 16 }} />}
          >
            {t('common:actions.close')}
          </Button>
        </CloseContainer>
        <Root>
          <NavContainer>
            <List>
              {menu.map((page, i) => (
                <ListItem key={i}>
                  <NavLink
                    href={page.to}
                    selected={router.pathname === page.to}
                  >
                    {page.title}
                  </NavLink>
                </ListItem>
              ))}
            </List>
          </NavContainer>
          <Separator />
          <ActionsContainer>
            <ActionContainer>
              <ProfileIconButton
                closeOnClick={onClose}
                isMenu={true}
                label="Entrar"
                alignLabel="right"
                labels={{
                  logoutDialog: {
                    accept: t('common:actions.yes'),
                    refuse: t('common:actions.no'),
                    text: t('common:logoutText'),
                  },
                }}
              />
            </ActionContainer>
            <ActionContainer>
              <FavoriteIconButton
                label="Meus Favoritos"
                alignLabel="right"
                dialogLabels={{
                  close: t('common:actions.close'),
                  loginText: t('navigation:authForm.title'),
                  onboardingDescription: t(
                    'navigation:authForm.onboarding_description'
                  ),
                  generic_login_error: t(
                    'navigation:authForm.generic_login_error'
                  ),
                  user_already_exists: t(
                    'navigation:authForm.user_already_exists'
                  ),
                  generic_onboarding_error: t(
                    'navigation:authForm.generic_onboarding_error'
                  ),
                  user_doesnt_exist: t('navigation:authForm.user_doesnt_exist'),

                  noAccount: t('navigation:authForm.no_account'),
                  hasAccount: t('navigation:authForm.has_account'),
                  create_account: t('navigation:authForm.create_account'),
                  onboardingText: t('navigation:authForm.onboarding_title'),
                  loginDescription: t('navigation:authForm.description'),
                  loginFormLabels: {
                    email_username: t('navigation:authForm.email_username'),
                    remember_me: t('navigation:authForm.remember_me'),
                    forgot_password: t('navigation:authForm.forgot_password'),
                    signin_submit: t('navigation:authForm.signin_submit'),
                    password: t('navigation:authForm.password'),
                  },
                  onboardingFormLabels: {
                    email: t('navigation:authForm.email_username'),
                    password: t('navigation:authForm.password'),
                    password_confirm: t(
                      'navigation:authForm.onboarding_confirm_password'
                    ),
                    reading_terms: t(
                      'navigation:authForm.onboarding_reading_terms'
                    ),
                    terms: t('navigation:authForm.onboarding_terms'),
                    onboarding_submit: t(
                      'navigation:authForm.onboarding_submit'
                    ),
                    password_error: t(
                      'navigation:authForm.onboarding_password_error'
                    ),
                    email_error: t(
                      'navigation:authForm.onboarding_email_error'
                    ),
                  },
                  social_continue_with: t('auth:social.continue_with'),
                  signing_method_or: t('auth:form.signing_method_or'),
                }}
              />
            </ActionContainer>
            <ActionContainer>
              <CartIconButton
                label="Meu carrinho"
                labels={{
                  cartItens: (n) => t('common:cart.cartItens', { n }),
                  checkout: t('common:actions.checkout'),
                  total: t('common:cart.total'),
                  emptyCart: t('common:cart.emptyCart'),
                  search: t('common:cart.search'),
                  itemRemovedFromCart: t('common:cart.itemRemovedFromCart'),
                  failedToRemoveItemFromCart: t(
                    'common:cart.failedToRemoveItemFromCart'
                  ),
                }}
              />
            </ActionContainer>
          </ActionsContainer>
        </Root>
      </DrawerDialog>
      <LogoutDialog
        open={logout}
        onLogout={onClose}
        close={() => setLogout(false)}
        labels={{
          accept: t('common:actions.yes'),
          refuse: t('common:actions.no'),
          text: t('common:logoutText'),
        }}
      />
    </>
  );
};

export default SideNavigation;
