import React from 'react';
import { Favorite } from '@mui/icons-material';
import LabelIconButton from '@bamboo/core-lib/components/LabelIconButton';
import { LoginDialog } from '@/components/layout/LoginDialog';
import api from '@bamboo/core-lib/api';
import { useSessionStore } from '@bamboo/core-lib/repo/session';
import {
  setFavoriteProduct,
  removeFavoriteProduct,
  useClips,
} from '@bamboo/core-lib/repo/clips';
import { createModal } from '@bamboo/core-lib/components/PromiseDialog';
import { useRouter } from 'next/router';
import type { LabelIconButtonProps } from '@bamboo/core-lib/components/LabelIconButton';
import type { LoginDialogProps } from '@/components/layout/LoginDialog';
import Heart from '@bamboo/core-lib/components/Icons/Heart';
import { addToWishlist } from '@/lib/seo/GAEvents';

export type FavoriteIconButtonProps = {
  label?: string;
  alignLabel?: LabelIconButtonProps['alignLabel'];
  dialogLabels?: LoginDialogProps['labels'];
};

const [rendererLoginDialog, promiseLoginDialog] = createModal(LoginDialog);

export const SaveFavoriteIconButton = (
  props: FavoriteIconButtonProps & {
    prodID: string;
  }
) => {
  const { label, alignLabel, dialogLabels, prodID } = props;
  const { user, jwt } = useSessionStore();
  const { favoriteProductByID, clipBySlug } = useClips();
  const isFavorite = !!favoriteProductByID?.[prodID];

  const saveFavorite = React.useCallback(() => {
    const Authorization = `Bearer ${jwt}`;
    return api
      .favoriteProductCreate({ prodID }, { headers: { Authorization } })
      .then((r) => {
        setFavoriteProduct(r.data.item);
        const clip = Object.keys(clipBySlug)
          .map((slug) => clipBySlug[slug])
          .find((c) => c?.prodID === prodID);
        if (clip) {
          addToWishlist(clip);
        }
      })
      .catch(console.warn);
  }, [jwt, prodID, clipBySlug]);

  const removeFavorite = React.useCallback(() => {
    const Authorization = `Bearer ${jwt}`;
    return api
      .favoriteProductDelete(
        {},
        {
          params: { prodID },
          headers: { Authorization },
        }
      )
      .then(() => {
        removeFavoriteProduct(prodID);
      })
      .catch(console.warn);
  }, [jwt, prodID]);

  const handleClick = React.useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (!user) {
        const logged = await promiseLoginDialog({
          labels: dialogLabels,
        });
        if (!logged) {
          return;
        }
      }
      if (isFavorite) {
        removeFavorite();
        return;
      }
      saveFavorite();
    },
    [user, dialogLabels, isFavorite, saveFavorite, removeFavorite]
  );

  return (
    <>
      <LabelIconButton
        label={label}
        alignLabel={alignLabel}
        onClick={handleClick}
        icon={isFavorite ? <Favorite /> : <Heart />}
      />
      {rendererLoginDialog}
    </>
  );
};

const FavoriteIconButton = (props: FavoriteIconButtonProps) => {
  const { label, alignLabel, dialogLabels } = props;
  const { user } = useSessionStore();
  const router = useRouter();

  const handleClick = React.useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (!user) {
        const logged = await promiseLoginDialog({
          labels: dialogLabels,
        });
        if (!logged) {
          return;
        }
      }
      router.push('/dashboard-user/favorites');
    },
    [user, router, dialogLabels]
  );

  return (
    <div>
      <LabelIconButton
        label={label}
        alignLabel={alignLabel}
        onClick={handleClick}
        icon={<Heart />}
      />
      {rendererLoginDialog}
    </div>
  );
};

export default FavoriteIconButton;
